// ui

@use "./vars";

body {
  font-size: 15px;
  background: $bg-color;
  color: $white-color;
  font-family: "Montserrat", sans-serif;
}

html {
  &.popup-open {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

input,
input::placeholder,
textarea,
textarea::placeholder,
button,
a {
  font-family: "Montserrat", sans-serif;
}

.container {
  width: 100%;
  margin-inline: auto;
  max-width: 576px;
}

.space-control {
  padding-bottom: 120px;
}
