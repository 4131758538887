@import "../../assets/vendor/vars";

.app-loader {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: $bg-color;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
