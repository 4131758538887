@import "../../../../assets/vendor/vars";

.build-list-item {
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  &__card {
    display: flex;
    background: rgba(42, 30, 84, 0.5);
    padding: 6px;
  }
  &__image {
    flex-shrink: 0;
    flex: 0.8;
    overflow: hidden;
    margin-right: 10px;
    min-width: 90px;
  }

  &__titleBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    padding-top: 1px;
  }

  &__title {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
  }

  &__content {
    flex: 2;
    flex-shrink: 0;
  }
  &__token {
    margin-top: 1px;
    flex: 1;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
  }

  &__earn {
    width: 100%;
    border-radius: 2px;
    background: #782297;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    white-space: nowrap;
    padding-inline: 4px;
    img {
      display: block;
      width: 16px;
    }
  }

  &__token-main {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 10px;
    flex: 1;
  }

  &__level {
    width: 100%;
    padding-inline: 4px;
    border-radius: 2px;
    background: #782297;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    align-self: flex-start;
    height: 16px;
  }

  &__enter-btn {
    margin-top: 14px;
    max-width: 150px;
  }
  &__upgrade {
    text-align: center;
    color: #fff;
    z-index: 1;
    position: relative;
    width: 100%;
    transition: all 0.3s;
    flex: 1;

    &:disabled {
      cursor: default;

      & svg {
        filter: contrast(0.5);
      }
    }
  }
  &__upgrade-lvl {
    font-size: 6px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      font-size: 8px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &__upgrade-title {
    margin-top: 2px;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__upgrade-price {
    margin-top: 3px;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      margin-left: 3px;
      display: block;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    img {
      width: 16px;
      display: block;
    }
  }
  &__upgrade-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &__upgrade-lvl {
    font-size: 6px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      font-size: 8px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  &__upgrade-title {
    margin-top: 2px;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__upgrade-price {
    margin-top: 3px;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      margin-left: 3px;
      display: block;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    img {
      width: 16px;
      display: block;
    }
  }
  &__upgrade-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &__progress-title {
    margin-top: 16px;
    margin-bottom: 6px;
    font-size: 12px;
  }

  &__progress {
    gap: 3px 2px;
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(3, 1fr);
    position: relative;
  }
  &__progress-card {
    position: relative;
    z-index: 1;
    text-align: center;
    padding-top: 3px;
    color: $white-color;
  }

  &__progress-circle {
    position: absolute;
    top: 51%;
    transform: translateY(-50%);
    svg {
      display: block;
    }
    &_first {
      left: 32%;
    }
    &_second {
      right: 32%;
    }
  }
  &__progress-value {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &__progress-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }
  &__progress-item {
    height: 36px;
    width: 100%;
    position: relative;
    cursor: pointer;
    transition: 0.3 all;

    &.hidden {
      opacity: 0;
    }

    &:disabled {
      cursor: default;
    }
  }

  &__adBtn {
    flex-direction: column;
  }
}
