@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.eot");
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url("../fonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat-SemiBold.woff") format("woff"),
    url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Bold.eot");
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url("../fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat-Bold.woff") format("woff"),
    url("../fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.eot");
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("../fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat-Regular.woff") format("woff"),
    url("../fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.eot");
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("../fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Montserrat-Medium.woff") format("woff"),
    url("../fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

// Raleway

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway-SemiBold.eot");
  src: local("Raleway SemiBold"), local("Raleway-SemiBold"),
    url("../fonts/Raleway-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Raleway-SemiBold.woff") format("woff"),
    url("../fonts/Raleway-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

// Orbitron

@font-face {
  font-family: "Orbitron";
  src: url("../fonts/Orbitron-Regular.eot");
  src: local("Orbitron Regular"), local("Orbitron-Regular"),
    url("../fonts/Orbitron-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Orbitron-Regular.woff2") format("woff2"),
    url("../fonts/Orbitron-Regular.woff") format("woff"),
    url("../fonts/Orbitron-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

// HausSquarePro
@font-face {
  font-family: "HausSquarePro";
  src: url("../fonts/HausSquarePro.ttf");
  font-style: normal;
}

// BOWLER
@font-face {
  font-family: "BOWLER";
  src: url("../fonts/BOWLER.otf");
  font-style: normal;
}

// handelsonsix.otf
@font-face {
  font-family: "handelsonsix";
  src: url("../fonts/handelsonsix.otf");
  font-style: normal;
}

// a_lcdnovaobl.ttf
@font-face {
  font-family: "a_lcdnovaobl";
  src: url("../fonts/1601623837ei.ttf");
  font-style: normal;
}
